import React from 'react'

const Mattress = () => {
  return (
    <div className="range">
        <div className="container">
            <h1>Mattresses</h1>
            <div className="items">
                <div>
                    <img src="../../../../media/catalogue/furniture/3d-backcare-mattress.webp" alt="3d-backcare" />
                    <p>3D Backcare Mattress <br></br> <span>From £320</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/3d-backcare-mattress.webp" alt="7-zone-memory-foam" />
                    <p>7 Zone Memory Foam Mattress <br></br> <span>From £300</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/hybrid-reflex-mattress.webp" alt="hydrid-reflex" />
                    <p>Hybrid Reflex Mattress <br></br> <span>From £270</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/majestic-9000-mattress.webp" alt="majestic-9000" />
                    <p>Majestic 9000 Mattress <br></br> <span>From £320</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/majestic-11000-mattress.webp" alt="majestic-11000" />
                    <p>Majestic 11000 Mattress <br></br> <span>From £320</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/natural-touch-mattress.webp" alt="natural-touch" />
                    <p>Natural Touch Mattress <br></br> <span>From £280</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/reverso-memory-mattress.webp" alt="reverso-memory" />
                    <p>Reverso Memory Mattress <br></br> <span>From £300</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/rock-1200-mattress.webp" alt="rock-1200" />
                    <p>Rock 1200 Mattress <br></br> <span>From £300</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/silk-memory-mattress.webp" alt="silk-memory" />
                    <p>Silk Memory Mattress <br></br> <span>From £270</span></p>
                </div>
                <div>
                    <img src="../../../../media/catalogue/furniture/hotel-howden-mattress.png" alt="hotel-howden" />
                    <p>Hotel Howden Mattress <br></br> <span>From £290</span></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mattress