import React from 'react'

import { Link } from 'react-router-dom';

const Catalogue = () => {
  return (
    <div className="catalogue">
          <h1>Catalogue</h1>
          <div className="categories">
            <Link to="/catalogue/carpets"><div className='category'>Carpets</div></Link>
            <Link to="/catalogue/laminate"><div className='category'>Laminate</div></Link>
            <Link to="/catalogue/furniture"><div className='category'>Furniture</div></Link>
            <Link to="/catalogue/vinyl"><div className='category'>Vinyl</div></Link>
            <Link to="/catalogue/mosque"><div className='category'>Mosque Carpets</div></Link>
            <Link to="/catalogue/other"><div className='category'>Other</div></Link>
          </div>
    </div>
  )
}

export default Catalogue